import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Helmet as ReactHelmet } from "react-helmet"
import styled from "@emotion/styled"

import Layout from "../../components/layout"
import Banniere from "../../elements/Banniere"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & > .content {
    padding-right: 12vw;
    padding-left: 12vw;

    span {
      width: 100% !important;
    }

    @media screen and (min-width: 872px) {
      img {
        max-width: calc(75vw - 24vw) !important;
        object-fit: scale-down;
      }
    }
  }

  & > .ban {
    display: grid;
    position: relative;


    & > .title {
      margin-left: 12vw;
      background-color: white;
      position: absolute;
      top: 110px;
      padding: 15px;

      @media screen and (max-width: 872px) {
        position: initial;
      }
    }
  }
`

export default function Permaculture() {
  const { graphCmsPage } = useStaticQuery(graphql`
    {
      graphCmsPage(name: {eq: "nous"}) {
        title
        content {
          html
        }
        assets {
          alt
          url
        }
        seo {
          title
          description
          image {
            url
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <Container>
        <ReactHelmet>
          <title>{graphCmsPage.seo.title}</title>
          <meta name="description" content={graphCmsPage.seo.description} />
          <meta name="og:title" content={graphCmsPage.seo.title} />
          <meta name="og:description" content={graphCmsPage.seo.description}/>
          <meta name="og:url" content="www.ferme-cultive.fr" />
        </ReactHelmet>

        <div className="ban">
          <Banniere />
          <div className="title">
            <h1>{graphCmsPage.title}</h1>
          </div>
        </div>

        <div className="content">
          <div dangerouslySetInnerHTML={{ __html: graphCmsPage.content[0].html }} />
          <img src={graphCmsPage.assets[0].url} alt={graphCmsPage.assets.url} />
        </div>
      </Container>
    </Layout>
  )
}
